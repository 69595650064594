.credit-debit-card-btn {
  width: 100%;
  padding: 0.75rem;
  background-color: #996cfe;
  color: #fff;
  font-family: "Noto Sans", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1rem;
  text-align: center;
  border: none;
  border-radius: 0.3125rem;
  cursor: pointer;
  margin-top: 1.5rem;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  visibility: hidden;
}

/* .credit-debit-card-btn:hover,
.credit-debit-card-btn:focus {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
} */

.payment-card-btn {
  width: 100%;
  display: inline-flex;
  padding: 0.75rem 0;
  justify-content: center;
  align-items: center;
  border-radius: 0.3125rem;
  background: #996cfe;
  color: #fff;
  font-family: "Noto Sans", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
  letter-spacing: -0.009rem;
  white-space: nowrap;
  cursor: pointer;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.payment-card-btn:hover,
.payment-card-btn:focus {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

@media (max-width: 650px) {
}
