body {
  margin: 0;
  font-family: 'Noto Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Styling for the slider */
.slider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 4px; /* Specified height */
  border-radius: 10px;
  background: white; /* White track */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Partial transparency */
  transition: opacity .2s; /* Smooth transition */
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully opaque on hover */
}

/* Styling for the slider thumb */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 20px; /* Width of the thumb */
  height: 20px; /* Height of the thumb */
  background: #996cfe; /* Purple background */
  border: 2px solid white; /* White border */
  cursor: pointer; /* Cursor to pointer */
  border-radius: 50%; /* Rounded edges */
  margin-top: -7px;
}

.slider::-moz-range-thumb {
  width: 10px; /* Width of the thumb */
  height: 10px; /* Height of the thumb */
  background: purple; /* Purple background */
  border: 3px solid white; /* White border */
  cursor: pointer; /* Cursor to pointer */
  border-radius: 50%; /* Rounded edges */
  
}

/* Styling for the filled part of the slider (only some browsers) */
.slider::-webkit-slider-runnable-track {
  height: 4px;
  border-radius: 10px;
  background: white;
  cursor: pointer;
}

.slider::-moz-range-track {
  background: white;
  height: 1px;
  cursor: pointer;
}



